import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import GetInTouchArea from "../../components/get-in-touch";
import MessageFrom from "../../components/message-from";
import SectionTitle from "../../components/title";
import { ContactUsSection, ContactUsinfoList, ContactUsCard } from "./style";
import jsonDataInit from "../../data/about/company-profile-additional.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const ContactUsArea = ({ data }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a78f4314e1d5002d37c8e9"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    return (
        <ContactUsSection>
            {preload && <Preload />}
            <Container>
                <ContactUsCard>
                    <Row className="gx-5 contact-us-title">
                        <Col lg={6}>
                            {/* {data?.section_title && (
                                <SectionTitle
                                    headingTitle={data?.section_title.title}
                                    {...data.section_title}
                                />
                            )} */}
                            <ContactUsinfoList>
                                {jsonData &&
                                    jsonData.map((item) => {
                                        return (
                                            <GetInTouchArea
                                                key={item.link}
                                                icon={item.image}
                                                title={item.title}
                                                content={item.text}
                                            />
                                        );
                                    })}
                            </ContactUsinfoList>
                        </Col>
                        <Col lg={6}>
                            {/* <SectionTitle
                                headingTitle="Send Us A Message"
                                showIcon={false}
                                align="left"
                            /> */}
                            <MessageFrom />
                        </Col>
                    </Row>
                </ContactUsCard>
            </Container>
        </ContactUsSection>
    );
};
ContactUsArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            description: PropTypes.string,
            icon: PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            }),
        }),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                icon: PropTypes.string,
                designation: PropTypes.string,
                headings: PropTypes.arrayOf(
                    PropTypes.shape({
                        level: PropTypes.string,
                        content: PropTypes.string,
                    })
                ),
            })
        ),
    }),
};
export default ContactUsArea;
