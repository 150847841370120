import { Col } from "react-bootstrap";
import { Form, Input, Textarea } from "./style";
import Button from "../../components/shared/button";
const MessageFrom = () => {
    return (
        <Form
            className="row gx-4"
            action="https://www.flexyform.com/f/b6df7712700652b2d5dee8ddd97eff3f9f3c01ef"
            method="POST"
            enctype="text/plain"
        >
            <Col sm={6}>
                <Input
                    className='form-control'
                    placeholder='Enter Your Name'
                    type='text'
                    name='name'
                />
            </Col>
            <Col sm={6}>
                <Input
                    className='form-control'
                    placeholder='Enter Your Email'
                    type='text'
                    name='email'
                />
            </Col>
            <Col sm={12}>
                <select id='inputState' className='form-select'>
                    <option value='DEFAULT' selected>
                        Choose Topic
                    </option>
                    <option value='1'>Fire Insurance</option>
                    <option value='2'>Motor Insurance</option>
                    <option value='3'>Merine Insurance</option>
                    <option value='4'>Merine Hull Insurance</option>
                    <option value='5'>Engineering Insurance</option>
                    <option value='6'>Health Insurance</option>
                    <option value='7'>Overseas Insurance</option>
                    <option value='8'>Miscellaneous Insurance</option>
                </select>
            </Col>
            <Col sm={12}>
                <Textarea
                    placeholder='Type your question'
                    className='form-control textarea-control'
                    name='message'
                    id='textarea'
                    cols='30'
                    rows='10'
                ></Textarea>
            </Col>
            <Col sm={12}>
                <Button
                    size='large'
                    shape='rounded5'
                    // path="/"
                    className='btn-absolute'
                    type="submit"
                    value="Submit"
                    formenctype="multipart/form-data"
                    onclick="alert('your message sent successfully')"
                >
                    Send
                    <i className='icofont-rounded-double-right'></i>
                </Button>
            </Col>
        </Form>
    );
};

export default MessageFrom;
